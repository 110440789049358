import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import QrLector from '../views/QrLector.vue';
import FilesDetails from '../views/FilesDetails.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/qr',
    name: 'qr',
    component: QrLector,
  },
  {
    path: '/files',
    name: 'files',
    component: FilesDetails,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

store.commit('initialize');

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.isLoggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
