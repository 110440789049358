<template>
  <div class="home">
    <div class="pt-2 pt-3" style="padding-left: 10px;">
      <b-button
        @click="logout"
        class="d-flex align-items-center px-0"
        style="background-color:transparent; color: black; border: none;"
      >
        <b-icon class="h3 my-0 mx-1" icon="box-arrow-in-left" />
        <span class="ml-1"> Salir </span>
      </b-button>
    </div>
    <h3 class="mt-5 mb-5">Notaria 134</h3>
    <MenuHome />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import router from '@/router';
import store from '@/store';
import MenuHome from '@/components/HomeMenu.vue';

export default ({

  components: {
    MenuHome,
  },

  setup() {
    const logout = () => {
      store.commit('logout');
      router.push('/login');
    };

    return {
      logout,
    };
  },
});
</script>
<style>
h3 {
  font-family: Raleway, Helvetica, Arial, sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 20px;
  margin-bottom: 0%;
}

</style>
