<template>
  <div class="main-view">
    <div class="central-containers" style="align-items: flex-end; padding-bottom: 1.5%;">
      <div class="button-container" style="margin-right: 5px;">
        <button class="box" @click="showOverlayQr('release_from_office')">
          <p>Lo que me estoy llevando de la notaria.</p>
        </button>
      </div>
      <div class="button-container">
        <button class="box" style="margin-left: 5px;"
        @click="showOverlayQr('deliver_to_dependency')">
          <p>Lo que estoy dejando en la dependencia.</p>
        </button>
      </div>
    </div>
    <div class="central-containers" style="align-items: flex-start; padding-top: 1.5%;">
      <div class="button-container">
        <button class="box" style="margin-right: 5px;"
        @click="showOverlayQr('pick_from_dependency')">
          <p>Lo que me estoy llevando de la dependencia.</p>
        </button>
      </div>
      <div class="button-container">
        <button class="box" style="margin-left: 5px;"
        @click="showOverlayQr('deliver_to_office')">
          <p>Lo que estoy regresando a la notaria.</p>
        </button>
      </div>
    </div>

    <!-- Overlay de la cámara -->
    <camera-overlay v-if="showOverlay"
    :qrEvent="qrEvent"
    @closeCameraOverlay="hideCamera" />

  </div>
</template>

<script>
import CameraOverlay from './OverlayQr.vue';

export default {
  components: {
    CameraOverlay,
  },
  data() {
    return {
      showOverlay: false,
      qrEvent: '',
    };
  },
  methods: {
    showOverlayQr(qrEvent) {
      this.qrEvent = qrEvent;
      this.showOverlay = true;
    },
    hideCamera() {
      this.showOverlay = false;
    },
  },
};
</script>

<style>
.box {
  border: 1px solid #ddd;
  padding: 15px 8px 0px 8px;
  text-align: center;
  border-radius: 10%;
  background-color: #3B7EB3;
  width: 170px;
  height: 180px;
  color: white;
  font-size: 18px;
}

.main-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.central-containers {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  height: 50%;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 55%;
}

.container {
  width: 100%;
  height: 76vh;
}
</style>
