/* eslint-disable no-import-assign */
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://baku.notaria134.birdman.dev',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
