import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    currentUser: {},
    authToken: '',
  },
  getters: {
  },
  mutations: {
    login(state, { data }) {
      state.isLoggedIn = true;
      state.currentUser = data.user;
      state.authToken = data.meta.auth_token;
      localStorage.setItem('appState', JSON.stringify(state));
    },
    logout(state) {
      state.isLoggedIn = false;
      state.currentUser = {};
      state.authToken = '';
      localStorage.removeItem('appState');
    },

    initialize(state) {
      const appState = localStorage.getItem('appState');
      if (appState) {
        const serializedAppState = JSON.parse(appState);
        state.isLoggedIn = serializedAppState.isLoggedIn;
        state.currentUser = serializedAppState.currentUser;
        state.authToken = serializedAppState.authToken;
      }
    },
  },
  actions: {
  },
  modules: {
  },
});
