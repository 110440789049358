<template>
    <div id="app">
      <vue-qrcode-reader @decode="onDecode"></vue-qrcode-reader>
      <p>{{ qrCodeData }}</p>
    </div>
  </template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import VueQrcodeReader from 'vue-qrcode-reader';

export default {
  components: {
    VueQrcodeReader,
  },
  data() {
    return {
      qrCodeData: '',
    };
  },
  methods: {
    onDecode(data) {
      this.qrCodeData = data;
    },
  },
};
</script>
