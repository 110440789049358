<template>
  <b-row class="m-1 d-flex login-container align-items-center">
    <b-col>
      <form @submit.prevent="login" style="font-weight: bold;">
        <b-form-group label="Username:" class="pt-3">
          <b-form-input id="username" type="text" v-model="sessionModel.username" />
        </b-form-group>

        <b-form-group label="Password:" class="pt-3">
          <b-form-input id="password" type="password" v-model="sessionModel.password" />
        </b-form-group>

        <b-button class="mt-3 w-100" type="submit" variant="primary">Login</b-button>
      </form>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable import/no-cycle */
import router from '@/router';
import store from '@/store';
import loginStore from '@/store/auth';
import Vue from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

export default {
  data() {
    return {
      sessionModel: {
        username: '',
        password: '',
      },
    };
  },
  setup() {
    Vue.use(Toast, {
      transition: 'Vue-Toastification__bounce',
      maxToasts: 20,
      newestOnTop: true,
    });
  },
  methods: {
    sendToast(text, type) {
      this.$toast(text, {
        position: 'bottom-center',
        timeout: 3689,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: true,
        rtl: false,
        type,
      });
    },
    async login() {
      loginStore.dispatch('login', { session: { username: this.sessionModel.username, password: this.sessionModel.password } }).then((res) => {
        console.log(res);
        store.commit('login', { data: res.data });
        router.push('/');
      }).catch((err) => {
        if (err.response.status === 422) {
          this.sendToast('Usuario o contraseña incorrectos', 'error');
        }
      });
    },
  },
};
</script>

<style scoped>

.login-container {
  height: 100vh;
}

</style>
